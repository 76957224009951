import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, Divider, H, P, Ui } from "common/components/atoms";
import CheckWithBorders from "common/components/atoms/CheckWithBorders/CheckWithBorders";
import DownloadButton from "common/components/atoms/DownloadButton/DownloadButton";
import FileUploader, { FilesDataSingle } from "common/components/atoms/FileUploader/FileUploader";
import GuideElementsIds from "common/guides/guide-configs/guide-elements-ids";
import { GuideIds } from "common/guides/guide-configs/types";
import { ChevronRightIcon, WarningExclamationMarkIcon } from "common/icons/svg";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../../transactions.context";

const DOWNLOAD_EXCEL_URL = `${process.env.REACT_APP_BLOB_PUBLIC_URL}common/imports/Unlisted-transactions-import.xlsx`;

const t = createTranslation(TranslationNS.pages, "company.transactions.importTransactions.downloadTemplate");
const tv = createTranslation(TranslationNS.validation);

const DownloadTemplate = () => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [uploadFieldError, setUploadFieldError] = useState<string | undefined>(undefined);

  const importFile = TransactionsContext.useStoreState((store) => store.importFile);
  const deleteExistingTransactions = TransactionsContext.useStoreState((store) => store.deleteExistingTransactions);
  const setImportFile = TransactionsContext.useStoreActions((actions) => actions.setImportFile);
  const setDeleteExistingTransactions = TransactionsContext.useStoreActions(
    (actions) => actions.setDeleteExistingTransactions
  );
  const postImportTransactionsExcel = TransactionsContext.useStoreActions(
    (actions) => actions.postImportTransactionsExcel
  );

  const onFileUpload = (file: FilesDataSingle | null) => {
    if (file) {
      setUploadFieldError(undefined);
    }
    setImportFile(file);
  };

  useEffect(() => {
    setImportFile(null);
    setUploadFieldError(undefined);
  }, [setImportFile]);

  const onSubmit = async () => {
    setIsLoading(true);

    if (!importFile?.newFile) {
      setUploadFieldError(tv("required"));
    }

    if (companyId && importFile?.newFile) {
      const success = await postImportTransactionsExcel({
        companyId: +companyId,
        deleteExistingTransactions,
        file: importFile.newFile,
      });

      if (success) {
        navigate(getEMPath(["importTransactions", "importStatus"], { companyId }));
      }
    }
    setIsLoading(false);
  };

  //  Add transactions step 3 =====================================================

  const ref = useRef(false);
  const currentGuideId = useStoreState((state) => state.guideModel.currentGuideId);
  const currentStepId = useStoreState((state) => state.guideModel.currentStepId);
  const moveNext = useStoreActions((actions) => actions.guideModel.moveNext);

  const isImportTransactionsGuideActive = currentGuideId === GuideIds.AddRecentTransactions;
  const isClearAndAddTransactionsActive = currentGuideId === GuideIds.ClearAndAddTransactions;

  useEffect(() => {
    if (ref.current) return;
    ref.current = true;

    if ((isImportTransactionsGuideActive || isClearAndAddTransactionsActive) && currentStepId === 1) {
      moveNext();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // =====================================================================================

  return (
    <WizardContent.Content step={1}>
      <H.xs className="mb-4">{t("title")}</H.xs>
      <Ui.m className="mb-2" bold>
        {t("importTypeTitle")}
      </Ui.m>
      <P.m className="mb-2">{t("importTypeDescription")}</P.m>
      <CheckWithBorders
        id={GuideElementsIds.ReplaceExistingTransactionsRadio}
        className="mb-2"
        handleChange={() => {
          if (isClearAndAddTransactionsActive) {
            moveNext();
          }
          setDeleteExistingTransactions(false);
        }}
        checked={!deleteExistingTransactions}
        value={"false"}
        name={"false"}
        label={t("importType.option1.title")}
        content={<P.s color="foregroundMedium">{t("importType.option2.description")}</P.s>}
      />
      <CheckWithBorders
        className="mb-4"
        id={GuideElementsIds.AddToExistingTransactionsRadio}
        handleChange={() => {
          if (isImportTransactionsGuideActive) {
            moveNext();
          }
          setDeleteExistingTransactions(true);
        }}
        checked={deleteExistingTransactions}
        value={"true"}
        name={"true"}
        label={t("importType.option2.title")}
        content={
          <div>
            <P.s color="foregroundMedium">{t("importType.option2.description")}</P.s>
            <P.s color="critical500" className="d-flex align-items-center">
              <WarningExclamationMarkIcon fontSize={16} />
              <P.s className="ms-half">{t("importType.option2.warning")}</P.s>
            </P.s>
          </div>
        }
      />
      <Ui.m className="mb-2" bold>
        {t("downloadTitle")}
      </Ui.m>
      <P.m className="mb-2">{t("downloadDescription")}</P.m>
      <DownloadButton downloadUrl={DOWNLOAD_EXCEL_URL} className="mb-4" text={t("downloadButton")} />
      <Divider className="mb-5" />
      <H.s className="mb-4">{t("uploadTitle")}</H.s>
      <P.m className="mb-2">{t("uploadDescription1")}</P.m>
      <P.s className="mb-2" color="foregroundMedium">
        {t("uploadDescription2")}
      </P.s>
      <FileUploader
        className="mb-7"
        accept=".xls, .xlsx"
        onChange={onFileUpload}
        multiple={false}
        filesOnly=".xls .xlsx"
        error={uploadFieldError}
        isTouched={true}
      />
      <div className="d-flex justify-content-end">
        <Button iconRight={<ChevronRightIcon />} onClick={onSubmit} isLoading={isLoading}>
          {t("continue")}
        </Button>
      </div>
    </WizardContent.Content>
  );
};

export default DownloadTemplate;
