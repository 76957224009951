import classNames from "classnames";

import { LinkHelpText, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { QuestionCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./SharesBlocksInfo.module.scss";

const t = createTranslation(TranslationNS.pages, "company.valuation");
const SharesBlocksInfo = () => {
  const valuation = useStoreState((state) => state.company.valuation);
  const fNumber = useFormatNumbers();

  return (
    <div
      className={classNames("d-flex gap-2 align-items-start align-items-stretch mt-3 mb-2", classes.sharePriceBlocks)}
    >
      <div className={classes.block}>
        <div className="d-flex justify-content-between w-100 mb-3">
          <div>
            <Ui.s color="foregroundLow" className="mb-1">
              {t("sharePrice")}
            </Ui.s>
            <Ui.xxl bold>{fNumber(valuation?.sharePrice, "sharePrice")}</Ui.xxl>
          </div>
          <div>
            <LinkHelpText
              type="modal"
              placement="left"
              title={t("sharePrice")}
              content={t("sharePriceDescription")}
              className={classes.tooltip}
            >
              <QuestionCircleIcon color={scssVariables.foregroundLow} width={24} height={24} />
            </LinkHelpText>
          </div>
        </div>
        <div className={classNames("pt-2 pb-2 d-flex justify-content-between w-100", classes.row)}>
          <Ui.s color="foregroundLow">
            {t("fdInclValue")}{" "}
            <LinkHelpText
              type="modal"
              placement="left"
              title={""}
              content={t.el("fdHelper.includeValue.description", {
                components: [<div key="1" className={classes.blockBorder} />],
              })}
              contentClass={"text-center"}
            >
              <QuestionCircleIcon color={scssVariables.foregroundLow} width={16} height={16} />
            </LinkHelpText>
          </Ui.s>
          <Ui.s color="foregroundLow">{fNumber(valuation?.fdSharePriceIncValue, "sharePrice")}</Ui.s>
        </div>
        <div className={classNames("pt-2 pb-2 d-flex justify-content-between w-100", classes.row)}>
          <Ui.s color="foregroundLow">
            {t("fdExclValue")}{" "}
            <LinkHelpText
              type="modal"
              placement="left"
              title={t("fdHelper.excludeValue.title")}
              content={t.el("fdHelper.excludeValue.description", {
                components: [<div key="1" className={classes.blockBorder} />],
              })}
              contentClass={"text-center"}
            >
              <QuestionCircleIcon color={scssVariables.foregroundLow} width={16} height={16} />
            </LinkHelpText>
          </Ui.s>
          <Ui.s color="foregroundLow">{fNumber(valuation?.fdSharePriceExcludeValue, "sharePrice")}</Ui.s>
        </div>
        <div className={classNames("pt-2 pb-2 d-flex justify-content-between w-100", classes.row)}>
          <Ui.s color="foregroundLow">
            {t("fdInclPoolsValue")}{" "}
            <LinkHelpText
              type="modal"
              placement="left"
              title={t("fdHelper.includeValueInclUnusedPools.title")}
              content={t.el("fdHelper.includeValueInclUnusedPools.description", {
                components: [<div key="1" className={classes.blockBorder} />],
              })}
              contentClass={"text-center"}
            >
              <QuestionCircleIcon color={scssVariables.foregroundLow} width={16} height={16} />
            </LinkHelpText>
          </Ui.s>
          <Ui.s color="foregroundLow">{fNumber(valuation?.fdSharePriceIncUnusedSharesInPools, "sharePrice")}</Ui.s>
        </div>
      </div>

      <div className={classes.block}>
        <div className="d-flex justify-content-between w-100 mb-3 ">
          <div>
            <Ui.s color="foregroundLow" className="mb-1">
              {t("totalShares")}
            </Ui.s>
            <Ui.xxl bold>{fNumber(valuation?.issuedShares, "amount")}</Ui.xxl>
          </div>
          <div>
            <LinkHelpText
              type="modal"
              placement="left"
              title={t("totalShares")}
              content={t("totalSharesDescription")}
              className={classes.tooltip}
            >
              <QuestionCircleIcon color={scssVariables.foregroundLow} width={24} height={24} />
            </LinkHelpText>
          </div>
        </div>
        <div className={classNames("pt-2 pb-2 d-flex justify-content-between w-100", classes.row)}>
          <Ui.s color="foregroundLow">
            {t("fullyDiluted")}{" "}
            <LinkHelpText
              type="modal"
              placement="left"
              title={t("fdHelper.numberOfShares.title")}
              content={t("fdHelper.numberOfShares.description")}
              className={classes.tooltip}
            >
              <QuestionCircleIcon color={scssVariables.foregroundLow} width={16} height={16} />
            </LinkHelpText>
          </Ui.s>
          <Ui.s color="foregroundLow">{fNumber(valuation?.fdTotalShares, "amount")}</Ui.s>
        </div>
        <div className={classNames("pt-2 pb-2 d-flex justify-content-between w-100", classes.row)}>
          <Ui.s color="foregroundLow">
            {t("fullyDilutedInclPools")}{" "}
            <LinkHelpText
              type="modal"
              placement="left"
              title={t("fdHelper.numberOfSharesInclPools.title")}
              content={t("fdHelper.numberOfSharesInclPools.description")}
              className={classes.tooltip}
            >
              <QuestionCircleIcon color={scssVariables.foregroundLow} width={16} height={16} />
            </LinkHelpText>
          </Ui.s>
          <Ui.s color="foregroundLow">{fNumber(valuation?.fdWithUnusedPoolsTotalShares, "amount")}</Ui.s>
        </div>
      </div>
    </div>
  );
};

export default SharesBlocksInfo;
