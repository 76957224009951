import { useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, H, P } from "common/components/atoms";
import { ChevronLeftIcon, ChevronRightIcon, ExclamationMarkCircle } from "common/icons/svg";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import DashboardTable from "../../../../../../fundraising/components/DashboardTable/DashboardTable";
import TransactionsContext from "../../../../transactions/transactions.context";
import useCapTablePreview from "./use-captable-preview";

const t = createTranslation(TranslationNS.pages, "company.capTable.importCaptable.captablePreview");

const CaptablePreview = () => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const previewData = TransactionsContext.useStoreState((store) => store.captablePreview);
  const importFile = TransactionsContext.useStoreState((store) => store.importFile);
  const warnings = TransactionsContext.useStoreState((store) => store.currentWarnings);
  const postConfirmImportCaptable = TransactionsContext.useStoreActions((actions) => actions.postConfirmImportCaptable);

  const { columns } = useCapTablePreview();

  const table = useReactTable({
    data: previewData,
    columns,
    meta: {
      footerShow: true,
    },
    getCoreRowModel: getCoreRowModel(),
  });

  const handleBack = () => {
    if (!warnings.length) {
      navigate(getEMPath(["importCaptable", "downloadTemplate"], { companyId }));
    } else {
      navigate(getEMPath(["importCaptable", "importStatus"], { companyId }));
    }
  };

  const handlePreviewTransactions = async () => {
    setIsLoading(true);
    if (companyId && importFile?.newFile) {
      const success = await postConfirmImportCaptable({
        companyId: +companyId,
        file: importFile.newFile,
      });

      if (success) {
        navigate(getEMPath(["ownership", "capTable"], { companyId }));
      }
    }
    setIsLoading(false);
  };

  return (
    <WizardContent.Content step={3}>
      <H.s className="mb-4">{t("title")}</H.s>
      <P.m className="mb-3">{t("description")}</P.m>
      <div
        className="d-flex justify-content-center py-1_half px-2 mb-4 rounded-2"
        style={{ backgroundColor: scssVariables.warning050 }}
      >
        <ExclamationMarkCircle fontSize={24} color={scssVariables.warning900} />
        <P.s className="ms-2">{t("warningMessage")}</P.s>
      </div>
      <DashboardTable type="capTable" table={table} />
      <div className="d-flex justify-content-between mt-7">
        <Button variant="secondary" iconLeft={<ChevronLeftIcon />} onClick={handleBack} isDisabled={isLoading}>
          {t("backBtn")}
        </Button>
        <Button iconRight={<ChevronRightIcon />} onClick={handlePreviewTransactions} isLoading={isLoading}>
          {t("finish")}
        </Button>
      </div>
    </WizardContent.Content>
  );
};

export default CaptablePreview;
