import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";

import { Button, P, Ui } from "common/components/atoms";
import CircleIconContainer from "common/components/atoms/CircleIconContainer/CircleIconContainer";
import { CheckIcon, CloseIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { GuideConfig } from "../../guide-configs/types";
import classes from "../../styles.module.scss";

type PT = {
  guide: GuideConfig;
};

const t = createTranslation(TranslationNS.guides, "guides");

const GuideCard: FC<PT> = ({ guide }) => {
  const { companyId } = useParams<{ companyId?: string }>();
  const [isLoading, setIsLoading] = useState(false);

  const hideGuide = useStoreActions((actions) => actions.guideModel.hideThunk);
  const getGuides = useStoreActions((actions) => actions.guideModel.getGuidesThunk);
  const setCurrentLearMoreModalId = useStoreActions((actions) => actions.guideModel.setCurrentLearMoreModalId);

  const handleHide = async () => {
    setIsLoading(true);

    const success = await hideGuide({
      companyId: Number(companyId),
      guideType: guide.id,
    });

    if (success) {
      await getGuides(Number(companyId));
    }

    setIsLoading(false);
  };

  const handleOpenLearnMore = () => {
    setCurrentLearMoreModalId(guide.id);
  };

  return (
    <div
      className={cn(
        guide.cardConfig.done ? classes.borderGreen : classes.border,
        classes.size,
        "rounded-2 d-flex flex-column p-2"
      )}
    >
      <div className="d-flex align-items-center mb-2">
        <div className="me-1">{guide.cardConfig.icon}</div>
        <>
          {guide.cardConfig.done && (
            <CircleIconContainer color={scssVariables.positive900}>
              <CheckIcon fontSize={24} />
            </CircleIconContainer>
          )}
          <Button size="s" variant="tertiary" className="ms-auto" isOnlyIcon onClick={handleHide} isLoading={isLoading}>
            <CloseIcon />
          </Button>
        </>
      </div>
      <Ui.m className="mb-1">{guide.cardConfig.title}</Ui.m>
      <P.s className="mb-2" color="foregroundLow">
        {guide.cardConfig.description}
      </P.s>
      <div className="d-flex mt-auto justify-content-end">
        <Button size="s" variant="secondary" onClick={handleOpenLearnMore} isDisabled={isLoading}>
          {t("useGuideBtn")}
        </Button>
      </div>
    </div>
  );
};

export default GuideCard;
