import { clone } from "ramda";

import { createTranslation, TranslationNS } from "translation";

import CircleIconContainer from "../../components/atoms/CircleIconContainer/CircleIconContainer";
import { PieChartIcon, TaxIcon, TotalValueIcon } from "../../icons/svg";
import { scssVariables } from "../../utils/constants";
import { Guide } from "../guide-service/types";
import GuideElementsIds from "./guide-elements-ids";
import { GuideIds, GuideNamesById, GuidesStructure } from "./types";

const t = createTranslation(TranslationNS.guides, "guides");

// We need to populate guides with state from API
export const generateGuides = (guides: Guide[], onDestroy: () => void): GuidesStructure => {
  const GuidesConfig: GuidesStructure = {
    UploadAsIsCapTable: {
      id: GuideIds.UploadAsIsCapTable,
      cardConfig: {
        title: t("uploadAsIsCaptable.card.title"),
        description: t("uploadAsIsCaptable.card.description"),
        starred: false,
        done: false,
        hidden: false,
        icon: (
          <CircleIconContainer color={scssVariables.additional7200}>
            <PieChartIcon fontSize={24} />
          </CircleIconContainer>
        ),
      },
      flowConfig: {
        steps: [
          {
            element: "#" + GuideElementsIds.ImportCaptableBtn,
            popover: {
              title: t("uploadAsIsCaptable.flow.step1.title"),
              description: t("uploadAsIsCaptable.flow.step1.description"),
              showButtons: [],
            },
          },
        ],
      },
    },

    // ============================================================ //

    ClearAndAddTransactions: {
      id: GuideIds.ClearAndAddTransactions,
      cardConfig: {
        title: t("clearAndAddTransactions.card.title"),
        description: t("clearAndAddTransactions.card.description"),
        starred: false,
        done: false,
        hidden: false,
        icon: (
          <CircleIconContainer color={scssVariables.additional9200}>
            <PieChartIcon fontSize={24} />
          </CircleIconContainer>
        ),
      },
      flowConfig: {
        steps: [
          {
            element: "#" + GuideElementsIds.TransactionNavTab,
            popover: {
              title: t("clearAndAddTransactions.flow.step1.title"),
              description: t("clearAndAddTransactions.flow.step1.description"),
              showButtons: [],
            },
          },
          {
            element: "#" + GuideElementsIds.ImportTransactionsBtn,
            popover: {
              title: t("clearAndAddTransactions.flow.step2.title"),
              description: t("clearAndAddTransactions.flow.step2.description"),
              showButtons: [],
            },
          },
          {
            element: "#" + GuideElementsIds.ReplaceExistingTransactionsRadio,
            popover: {
              title: t("clearAndAddTransactions.flow.step3.title"),
              description: t("clearAndAddTransactions.flow.step3.description"),
              showButtons: ["next", "close"],
            },
          },
        ],
      },
    },

    // ============================================================ //

    AddRecentTransactions: {
      id: GuideIds.AddRecentTransactions,
      cardConfig: {
        title: t("addRecentTransactions.card.title"),
        description: t("addRecentTransactions.card.description"),
        starred: false,
        done: false,
        hidden: false,
        icon: (
          <CircleIconContainer color={scssVariables.additional5200}>
            <PieChartIcon fontSize={24} />
          </CircleIconContainer>
        ),
      },
      flowConfig: {
        steps: [
          {
            element: "#" + GuideElementsIds.TransactionNavTab,
            popover: {
              title: t("addRecentTransactions.flow.step1.title"),
              description: t("addRecentTransactions.flow.step1.description"),
              showButtons: [],
            },
          },
          {
            element: "#" + GuideElementsIds.ImportTransactionsBtn,
            popover: {
              title: t("addRecentTransactions.flow.step2.title"),
              description: t("addRecentTransactions.flow.step2.description"),
              showButtons: [],
            },
          },
          {
            element: "#" + GuideElementsIds.AddToExistingTransactionsRadio,
            popover: {
              title: t("addRecentTransactions.flow.step3.title"),
              description: t("addRecentTransactions.flow.step3.description"),
              showButtons: ["next", "close"],
            },
          },
        ],
      },
    },

    // ============================================================ //

    AddNominalValue: {
      id: GuideIds.AddNominalValue,
      cardConfig: {
        title: t("addNominalValue.card.title"),
        description: t("addNominalValue.card.description"),
        starred: false,
        done: false,
        hidden: false,
        icon: (
          <CircleIconContainer color={scssVariables.additional5200}>
            <TotalValueIcon fontSize={24} />
          </CircleIconContainer>
        ),
      },
      flowConfig: {
        steps: [
          {
            element: "#" + GuideElementsIds.TransactionNavTab,
            popover: {
              title: t("addNominalValue.flow.step1.title"),
              description: t("addNominalValue.flow.step1.description"),
              showButtons: [],
            },
          },
          {
            element: "#" + GuideElementsIds.AddTransactionBtn,
            popover: {
              title: t("addNominalValue.flow.step2.title"),
              description: t("addNominalValue.flow.step2.description"),
              showButtons: [],
            },
          },
          {
            element: "#" + GuideElementsIds.ChangeNominalValueBtn,
            popover: {
              title: t("addNominalValue.flow.step3.title"),
              description: t("addNominalValue.flow.step3.description"),
              showButtons: [],
            },
          },
          {
            element: "#" + GuideElementsIds.NominalValueDateInput,
            popover: {
              title: t("addNominalValue.flow.step4.title"),
              description: t("addNominalValue.flow.step4.description"),
              side: "bottom",
              showButtons: ["next", "close"],
            },
          },
        ],
      },
    },

    // ============================================================ //

    AddCompanyValuation: {
      id: GuideIds.AddCompanyValuation,
      cardConfig: {
        title: t("addCompanyValuation.card.title"),
        description: t("addCompanyValuation.card.description"),
        starred: false,
        done: false,
        hidden: false,
        icon: (
          <CircleIconContainer color={scssVariables.additional11200}>
            <TaxIcon fontSize={24} />
          </CircleIconContainer>
        ),
      },
      flowConfig: {
        steps: [
          {
            element: "#" + GuideElementsIds.ValuationNavTab,
            popover: {
              title: t("addCompanyValuation.flow.step1.title"),
              description: t("addCompanyValuation.flow.step1.description"),
              showButtons: [],
            },
          },
          {
            element: "#" + GuideElementsIds.EditValuationBtn,
            popover: {
              title: t("addCompanyValuation.flow.step2.title"),
              description: t("addCompanyValuation.flow.step2.description"),
              showButtons: [],
            },
          },
          {
            element: "#" + GuideElementsIds.ValuationForm,
            popover: {
              side: "left",
              description: t("addCompanyValuation.flow.step3.description"),
              showButtons: ["close"],
            },
          },
          {
            element: "#" + GuideElementsIds.ValuationChart,
            popover: {
              title: t("addCompanyValuation.flow.step4.title"),
              description: t("addCompanyValuation.flow.step4.description"),
              showButtons: ["close", "next"],
            },
          },
        ],
      },
    },

    // ============================================================ //
  };

  const guidesCopy = clone(GuidesConfig);

  guides.forEach(({ guide, done, hidden, starred }) => {
    const guideName = GuideNamesById[guide];

    if (guideName && guidesCopy[guideName]) {
      Object.assign(guidesCopy[guideName].cardConfig, { done, hidden, starred });

      if (guideName === "AddCompanyValuation") {
        guidesCopy[guideName].flowConfig.onDestroyed = (element) => {
          if (element?.id === GuideElementsIds.ValuationForm) return;

          onDestroy();
        };
      } else {
        guidesCopy[guideName].flowConfig.onDestroyed = onDestroy;
      }
    }
  });

  return guidesCopy;
};
