import { FC, useMemo, useState } from "react";

import Table from "common/components/atoms/Table/Table";
import { Ui } from "common/components/atoms/Typography";
import { sortHistoryValuation } from "common/helpers";
import { sortingParams } from "common/types/Collapsible.types";
import { useStoreState } from "store/store";
import { ValuationSingle } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import ValuationTableBody from "../ValuationTable/ValuationTableBody";
import ValuationTableHead from "../ValuationTable/ValuationTableHead";
import classes from "./ValuationHistory.module.scss";

const t = createTranslation(TranslationNS.pages, "company.valuation");

export type ValuationTableSortValue = {
  field: keyof Pick<ValuationSingle, "title" | "editor" | "valuation" | "validFrom">;
  type: keyof typeof sortingParams;
};

const ValuationHistory: FC = () => {
  const { valuation } = useStoreState((state) => state.company);

  const [activeSortedValue, setActiveSortedValue] = useState<ValuationTableSortValue>({
    field: "validFrom",
    type: sortingParams.desc,
  });

  const valuationSorted = useMemo(() => sortHistoryValuation(valuation?.valuations || []), [valuation?.valuations]);

  const rows = useMemo(
    () => [
      {
        key: "title",
        value: t("valuationHistoryTable.title"),
      },

      {
        key: "validFrom",
        value: t("valuationHistoryTable.date"),
      },
      {
        key: "editor",
        value: t("valuationHistoryTable.editor"),
      },

      {
        key: "description",
        value: t("valuationHistoryTable.description"),
        sortDisabled: true,
      },

      {
        key: "valuation",
        value: t("valuationHistoryTable.valuation"),
      },
    ],
    []
  );

  return (
    <div className={classes.container}>
      <Ui.xl bold className="ms-3 pt-4 mb-3">
        {t("valuationHistory")}
      </Ui.xl>

      <Table
        className={classes.table}
        headComponent={
          <ValuationTableHead rows={rows} sortedValue={activeSortedValue} setSortedValue={setActiveSortedValue} />
        }
        bodyComponent={<ValuationTableBody data={valuationSorted} sortedValue={activeSortedValue} />}
      />
    </div>
  );
};

export default ValuationHistory;
