import { FC, useMemo } from "react";
import cn from "classnames";
import * as R from "ramda";

import { LinkHelpText, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { QuestionCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./TaxableBlocksInfo.module.scss";

const t = createTranslation(TranslationNS.pages, "company.valuation");

const TaxableBlocksInfo: FC = () => {
  const valuation = useStoreState((state) => state.company.valuation);
  const fNumber = useFormatNumbers();

  const items = useMemo(
    () => [
      {
        direction: "left",
        title: t("nominalShareValue"),
        value: fNumber(valuation?.nominalValue, "unitPrice"),
        description: t("nominalShareValueDescription"),
      },
      {
        direction: "bottom",
        title: t("shareCapital"),
        value: (
          <>{fNumber(R.defaultTo(0, valuation?.issuedShares) * R.defaultTo(0, valuation?.nominalValue), "value")}</>
        ),
        description: t("shareCapitalDescription"),
      },
      {
        direction: "right",
        title: t("taxableValuePerShare"),
        value: fNumber(valuation?.taxableValue, "unitPrice"),
        description: t("taxableValuePerShareDescription"),
      },
      {
        direction: "right",
        title: t("totalTaxableValue"),
        value: <>{fNumber(valuation?.totalTaxableValue, "value")}</>,
        description: t("totalTaxableValueDescription"),
      },
    ],
    [fNumber, valuation?.issuedShares, valuation?.nominalValue, valuation?.taxableValue, valuation?.totalTaxableValue]
  );

  return (
    <div className={cn(classes["overview-details"])}>
      {items.map((item) => (
        <div key={item.title} className={cn("position-relative", classes.item)}>
          <div className="d-flex justify-content-between w-100">
            <Ui.s color="foregroundLow">{item.title}</Ui.s>
            <LinkHelpText
              type="modal"
              placement="left"
              title={item.title}
              content={item.description}
              className={classes.tooltip}
            >
              <QuestionCircleIcon color={scssVariables.foregroundLow} width={24} height={24} />
            </LinkHelpText>
          </div>

          <Ui.l bold>{item.value}</Ui.l>
        </div>
      ))}
    </div>
  );
};

export default TaxableBlocksInfo;
