import React, { memo } from "react";
import classNames from "classnames";
import { and, equals } from "ramda";

import { Ui } from "common/components/atoms/Typography";
import { FilledArrowDownIcon } from "common/icons/svg";
import { sortingParams } from "common/types/Collapsible.types";

import classes from "../../valuation.module.scss";
import { ValuationTableSortValue } from "../ValuationHistory/ValuationHistory";

type ValuationTableHeadProps = {
  sortedValue: ValuationTableSortValue;
  rows: { key: string; value: string; sortDisabled?: boolean }[];
  setSortedValue: (e: ValuationTableSortValue) => void;
};

const ValuationTableHead: React.FC<ValuationTableHeadProps> = ({ rows, sortedValue, setSortedValue }) => {
  return (
    <thead className={classes["table-head"]}>
      <tr>
        {rows.map((el, i, array) => (
          <th
            key={el.key}
            onClick={
              el?.sortDisabled
                ? undefined
                : () => {
                    setSortedValue({
                      field: el.key as ValuationTableSortValue["field"],
                      type:
                        sortedValue.field !== el.key
                          ? sortingParams.inc
                          : sortedValue.type === sortingParams.inc
                          ? sortingParams.desc
                          : sortingParams.inc,
                    });
                  }
            }
          >
            <div
              className={classNames("d-flex align-items-center", {
                "justify-content-end": i === array.length - 1,
                "cursor-pointer": !el?.sortDisabled,
              })}
            >
              <Ui.xs color="foregroundMedium">{el.value}</Ui.xs>

              <span
                className={classNames(classes.sort, {
                  invisible: !equals(sortedValue.field, el.key),
                  [classes["rotated"]]: and(
                    equals(sortedValue.field, el.key),
                    equals(sortedValue.type, sortingParams.inc)
                  ),
                })}
              >
                <FilledArrowDownIcon />
              </span>
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default memo(ValuationTableHead);
