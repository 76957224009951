import { FC, useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, ChecksGroup, DatePicker, Divider, P, SlidePanel, TextArea, TextField } from "common/components/atoms";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import ValuationContext from "../../ValuationContext";

const t = createTranslation("pages", "company.valuation");
const tv = createTranslation(TranslationNS.validation);

type ValuesForm = {
  taxableValue?: number;
  valuationDate: string;
  sendEmail: boolean;
  emailComment: string;
};

const EditTaxableValue: FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const { getValuationThunk } = useStoreActions((actions) => actions.company);
  const { isTaxableValueEdit } = ValuationContext.useStoreState((state) => state);
  const { setIsTaxableValueEdit } = ValuationContext.useStoreActions((actions) => actions);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        taxableValue: Yup.number()
          .required(tv("required"))
          .min(0.000001, tv("minNumber", { number: "0.000001" })),
        valuationDate: Yup.string().required(tv("required")),
        sendEmail: Yup.boolean(),
        emailComment: Yup.string(),
      }),
    []
  );

  const handleClose = () => {
    setIsTaxableValueEdit(false);
    resetForm();
  };

  const handleSubmitValuation = async (values: ValuesForm) => {
    try {
      await axios.post("/api/company/valuation/taxable-value", { companyId, ...values });
      await getValuationThunk(Number(companyId));
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const { values, handleChange, handleBlur, errors, touched, handleSubmit, setFieldValue, isSubmitting, resetForm } =
    useFormik<ValuesForm>({
      initialValues: {
        taxableValue: undefined,
        valuationDate: "",
        sendEmail: false,
        emailComment: "",
      },
      onSubmit: handleSubmitValuation,
      validationSchema,
    });

  const handleDateChange = useCallback(
    (date: Date | string, name?: string) => {
      if (name) {
        setFieldValue(name, date);
      }
    },
    [setFieldValue]
  );

  return (
    <SlidePanel data-testid="offcanvas-container-test-id" show={isTaxableValueEdit} onHide={handleClose}>
      <Form onSubmit={handleSubmit}>
        <SlidePanel.Header title={t("updateTaxableValue")} onHide={handleClose} />
        <SlidePanel.Section title={t("valuationDetails")}>
          <TextField
            className="mt-2"
            name="taxableValue"
            label="Enter taxable value per share"
            type="number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.taxableValue}
            error={errors.taxableValue}
            isTouched={touched.taxableValue}
          />
          <DatePicker
            className="mt-4"
            name="valuationDate"
            label="Registration date"
            date={values.valuationDate}
            onChange={handleDateChange}
            onBlur={handleBlur}
            error={errors.valuationDate}
            isTouched={touched.valuationDate}
            isDateOnlyString
          />
          <Divider />

          <ChecksGroup className="mb-4">
            <ChecksGroup.Check
              name="sendEmail"
              label={t("checkEmailTitle")}
              description={t("checkEmailDescription")}
              checked={values.sendEmail}
              onChange={handleChange}
            />
          </ChecksGroup>

          {values.sendEmail && (
            <TextArea
              className="mb-7"
              name="emailComment"
              isOptional
              value={values.emailComment}
              isTouched={touched.emailComment}
              error={errors.emailComment}
              onChange={handleChange}
              label={t("additionalCommentTitle")}
            />
          )}
          <P.m className="mt-5">{t("updatingValueDescription")}</P.m>
        </SlidePanel.Section>
        <SlidePanel.Actions>
          <Button isLoading={isSubmitting} variant="primary" type="submit">
            Update Value
          </Button>
          <Button onClick={handleClose} isDisabled={isSubmitting} className="ms-2" variant="secondary">
            Cancel
          </Button>
        </SlidePanel.Actions>
      </Form>
    </SlidePanel>
  );
};

export default EditTaxableValue;
