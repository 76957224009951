import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getEMPath, getPath } from "app/Router/RouterHelper";
import useStripeSubscription from "common/hooks/useStripeSubscription";
import { useStoreActions, useStoreState } from "store/store";

type PT = {
  companyId: number;
};

const CheckSubscription: FC<PT> = ({ companyId }) => {
  const navigate = useNavigate();
  const { subscription, connectCompanySubscription } = useStripeSubscription(Number(companyId));
  const [newCompanyActivated, setNewCompanyActivated] = useState(false);

  const invitedUser = useStoreState((state) => state.invitedUser.invitedUser);
  const integrations = useStoreState((state) => state.companyOnboardingModel.integrations);
  const setInvitedUser = useStoreActions((actions) => actions.invitedUser.setInvitedUser);

  useEffect(() => {
    if (invitedUser?.entityId) {
      setNewCompanyActivated(true);
    }
  }, [invitedUser?.entityId]);

  useEffect(() => {
    if (subscription && !subscription?.hasSubscription && invitedUser?.entityId && companyId) {
      connectCompanySubscription().then((res: any) => {
        if (res.subscriptionFound) {
          navigate(getEMPath(["ownership", "capTable"], { companyId }), {
            replace: true,
          });

          setInvitedUser(null);
        }
      });
    }
  }, [
    companyId,
    connectCompanySubscription,
    integrations?.bregg,
    integrations?.uniMicro,
    invitedUser?.entityId,
    navigate,
    setInvitedUser,
    subscription,
    subscription?.hasSubscription,
  ]);

  useEffect(() => {
    if (!newCompanyActivated) {
      if (companyId && subscription && subscription?.hasSubscription) {
        navigate(getEMPath(["ownership", "capTable"], { companyId }), {
          replace: true,
        });

        setInvitedUser(null);
      }

      if (companyId && subscription && !subscription?.hasSubscription && !invitedUser?.entityId) {
        navigate(getPath(["onboard", "company", "pricing"], { companyId }));

        setInvitedUser(null);
      }
    }
  }, [
    companyId,
    integrations?.bregg,
    integrations?.uniMicro,
    invitedUser?.entityId,
    navigate,
    newCompanyActivated,
    setInvitedUser,
    subscription,
    subscription?.hasSubscription,
  ]);

  return <></>;
};

export default CheckSubscription;
