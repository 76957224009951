import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, Divider, H, P } from "common/components/atoms";
import DownloadButton from "common/components/atoms/DownloadButton/DownloadButton";
import FileUploader, { FilesDataSingle } from "common/components/atoms/FileUploader/FileUploader";
import { ChevronRightIcon } from "common/icons/svg";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../../transactions/transactions.context";

const DOWNLOAD_EXCEL_URL = `${process.env.REACT_APP_BLOB_PUBLIC_URL}common/imports/Unlisted-cap-table-import.xlsx`;

const t = createTranslation(TranslationNS.pages, "company.capTable.importCaptable.downloadTemplate");
const tv = createTranslation(TranslationNS.validation);

const DownloadTemplate = () => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [uploadFieldError, setUploadFieldError] = useState<string | undefined>(undefined);

  const importFile = TransactionsContext.useStoreState((store) => store.importFile);
  const setImportFile = TransactionsContext.useStoreActions((actions) => actions.setImportFile);
  const postImportCaptableExcel = TransactionsContext.useStoreActions((actions) => actions.postImportCaptableExcel);

  const onFileUpload = (file: FilesDataSingle | null) => {
    if (file) {
      setUploadFieldError(undefined);
    }
    setImportFile(file);
  };

  useEffect(() => {
    setImportFile(null);
    setUploadFieldError(undefined);
  }, [setImportFile]);

  const onSubmit = async () => {
    setIsLoading(true);

    if (!importFile?.newFile) {
      setUploadFieldError(tv("required"));
    }

    if (companyId && importFile?.newFile) {
      const success = await postImportCaptableExcel({
        companyId: +companyId,
        file: importFile.newFile,
      });

      if (success) {
        navigate(getEMPath(["importCaptable", "importStatus"], { companyId }));
      }
    }
    setIsLoading(false);
  };

  return (
    <WizardContent.Content step={1}>
      <H.xs className="mb-4">{t("title")}</H.xs>
      <P.m className="mb-2" bold>
        {t("downloadTitle")}
      </P.m>
      <P.s className="mb-4">{t("downloadDescription")}</P.s>
      <DownloadButton downloadUrl={DOWNLOAD_EXCEL_URL} className="mb-4" text={t("downloadButton")} />
      <Divider className="mb-5" />
      <H.s className="mb-4">{t("uploadTitle")}</H.s>
      <P.m className="mb-2">{t("uploadDescription1")}</P.m>
      <P.s className="mb-2" color="foregroundMedium">
        {t("uploadDescription2")}
      </P.s>
      <FileUploader
        className="mb-7"
        accept=".xls, .xlsx"
        onChange={onFileUpload}
        multiple={false}
        filesOnly=".xls .xlsx"
        error={uploadFieldError}
        isTouched={true}
      />
      <div className="d-flex justify-content-end">
        <Button iconRight={<ChevronRightIcon />} onClick={onSubmit} isLoading={isLoading}>
          {t("continue")}
        </Button>
      </div>
    </WizardContent.Content>
  );
};

export default DownloadTemplate;
