import { useCallback, useState } from "react";
import { Form as Fform, Formik } from "formik";

import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import CheckSubscription from "./check-subscription";
import Form from "./form";
import { CompanyInformationValues } from "./types";
import useCompanyInfoForm from "./use-company-info-form";

export enum ImportMode {
  NotSelected,
  NorwayImport,
  NorwayManual,
  Foreign,
}

const t = createTranslation(TranslationNS.pages, "onboard.company.companyInformation");

const CompanyInformation = () => {
  const [newCompanyId, setNewCompanyId] = useState<number | null>(null);
  const [importMode, setImportMode] = useState<ImportMode>(ImportMode.NotSelected);
  const { initialValues, validationSchema } = useCompanyInfoForm();
  const postNorwegianCompanyThunk = useStoreActions(
    (actions) => actions.companyOnboardingModel.postNorwegianCompanyThunk
  );
  const postForeignCompanyThunk = useStoreActions((actions) => actions.companyOnboardingModel.postForeignCompanyThunk);
  const getAccountThunk = useStoreActions((actions) => actions.account.getAccountThunk);

  const handleSubmit = useCallback(
    async (values: CompanyInformationValues) => {
      try {
        if (importMode === ImportMode.NorwayImport && values.selectedCompanyId) {
          const companyId = await postNorwegianCompanyThunk({ organizationNumber: values.selectedCompanyId || "" });
          if (companyId) {
            setNewCompanyId(companyId);
            notify(t("dataImported"), true, "success", 3000);
            getAccountThunk();
          }
        }

        if (importMode === ImportMode.NorwayManual || importMode === ImportMode.Foreign) {
          const { selectedCompanyId, ...rest } = values;
          const companyId = await postForeignCompanyThunk(rest);

          if (companyId) {
            setNewCompanyId(companyId);
            getAccountThunk();
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    [getAccountThunk, importMode, postForeignCompanyThunk, postNorwegianCompanyThunk]
  );

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        <Fform>
          <Form importMode={importMode} setImportMode={setImportMode} />
        </Fform>
      </Formik>
      {newCompanyId && <CheckSubscription companyId={newCompanyId} />}
    </>
  );
};

export default CompanyInformation;
