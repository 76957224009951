import { FC } from "react";
import { Image } from "react-bootstrap";
import cn from "classnames";

import { Button, Divider, H, P, Ui } from "common/components/atoms";
import CircleIconContainer from "common/components/atoms/CircleIconContainer/CircleIconContainer";
import { CloseIcon, LinkIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { GuideIds } from "../../guide-configs/types";
import SpotImg from "../images/Spot_04.png";
import classes from "../styles.module.scss";
import { BOOK_MEETING_URL, BRREG_URL, ContentPT } from "../types";

const t = createTranslation(TranslationNS.guides, "guides.addNominalValue.learnMore");

const LearnAddNominalValue: FC<ContentPT> = ({ onHide }) => {
  const setCurrentLearMoreModalId = useStoreActions((state) => state.guideModel.setCurrentLearMoreModalId);
  const setCurrentGuideId = useStoreActions((state) => state.guideModel.setCurrentGuideId);

  const handleGuideMe = () => {
    setCurrentLearMoreModalId(null);
    setCurrentGuideId(GuideIds.AddNominalValue);
  };

  return (
    <div className="d-flex flex-column px-7 pt-2 pb-8">
      <Button size="s" variant="tertiary" isOnlyIcon className="ms-auto mb-1" onClick={onHide}>
        <CloseIcon fontSize={24} />
      </Button>
      <div className="d-flex gap-4">
        <div className="w-50">
          <H.l className="mb-5">{t("title")}</H.l>
          <P.xl className="mb-5" color="foregroundMedium">
            {t("paragraph1")}
          </P.xl>
          <P.m className="mb-3" color="foregroundMedium">
            {t("paragraph2")}
          </P.m>
          <P.m className="mb-5" color="foregroundMedium">
            {t("paragraph3")}
          </P.m>
          <Button className="mb-5" onClick={handleGuideMe}>
            {t("guideMe")}
          </Button>
          <Divider className="mt-0 mb-5" />
          <H.xxs className="mb-4">{t("subtitle")}</H.xxs>
          <div style={{ color: scssVariables.foregroundMedium }}>
            <div className="d-flex mb-4">
              <CircleIconContainer color={scssVariables.additional5200} className={cn("me-1_half", classes.circleSize)}>
                <Ui.m bold>1</Ui.m>
              </CircleIconContainer>
              <div>
                <Ui.m bold className="mb-1">
                  {t("step1.title")}
                </Ui.m>
                <P.m>{t("step1.paragraph")}</P.m>
              </div>
            </div>
            <div className="d-flex mb-4">
              <CircleIconContainer color={scssVariables.additional5200} className={cn("me-1_half", classes.circleSize)}>
                <Ui.m bold>2</Ui.m>
              </CircleIconContainer>
              <div>
                <Ui.m bold className="mb-1">
                  {t("step2.title")}
                </Ui.m>
                <P.m>
                  <ul className="ps-1_half">
                    <li>{t("step2.paragraphBullets.bullet1")}</li>
                    <li>{t("step2.paragraphBullets.bullet2")}</li>
                    <li>{t("step2.paragraphBullets.bullet3")}</li>
                  </ul>
                </P.m>
              </div>
            </div>
          </div>
        </div>
        <div className="w-50">
          <div
            className={cn(classes.imageContainer, "d-flex justify-content-center align-items-center mb-5")}
            style={{ backgroundColor: scssVariables.additional5200 }}
          >
            <Image src={SpotImg} width={240} height={240} />
          </div>
          <H.xxs className="mb-3">{t("linksTitle")}</H.xxs>
          <Button
            size="s"
            className="mb-3 px-1"
            variant="tertiary"
            as="a"
            target="_blank"
            rel="noreferrer"
            iconLeft={<LinkIcon />}
            href={BRREG_URL}
          >
            <Ui.m underline>{t("link1")}</Ui.m>
          </Button>
          <H.xxs className="mb-3">{t("assistTitle")}</H.xxs>
          <P.m className="mb-3" color="foregroundMedium">
            {t("assistParagraph")}
          </P.m>
          <Button variant="secondary" as="a" target="_blank" rel="noreferrer" href={BOOK_MEETING_URL}>
            {t("bookBtn")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LearnAddNominalValue;
