import { useCallback, useEffect } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import { Button, H, P } from "common/components/atoms";
import { CheckIcon, UnlistedIcon } from "common/icons/svg";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./Congratulations.module.scss";

const t = createTranslation(TranslationNS.pages, "fundraising.finisCampaign.congratulations");

const Congratulations = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const navigate = useNavigate();
  const { state } = useLocation();

  const setIsWizardLayoutAction = useStoreActions((actions) => actions.app.setIsWizardLayout);

  useEffect(() => {
    setIsWizardLayoutAction(true);
    return () => {
      setIsWizardLayoutAction(false);
    };
  }, [setIsWizardLayoutAction]);

  const handleContinue = useCallback(() => {
    navigate(getPath(["fundraising", "updateValuation"], { companyId }), { state });
  }, [companyId, navigate, state]);

  return (
    <div className={classes.wrap}>
      <div className="p-5">
        <UnlistedIcon fontSize={28} />
      </div>
      <div className={classes.container}>
        <div className={classes.success}>
          <ConfettiExplosion />
          <div className={classes.circle}>
            <CheckIcon fontSize={67} />
          </div>
          <H.xs className="mt-5">{t("title")}</H.xs>
          <P.m className="text-center mt-3">{t("description_1")}</P.m>
          <P.m className="text-center mt-3">{t("description_2")}</P.m>
          <Button variant={"secondary"} className="mt-5" onClick={handleContinue}>
            {t("continue")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Congratulations;
