import { FC, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { LinkHelpText, Ui } from "common/components/atoms";
import GuideElementsIds from "common/guides/guide-configs/guide-elements-ids";
import { GuideIds } from "common/guides/guide-configs/types";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { QuestionCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../valuation.module.scss";
import CompanyValuationChart from "./CompanyValuationChart";
import SharesBlocksInfo from "./SharesBlocksInfo/SharesBlocksInfo";
import TaxableBlocksInfo from "./TaxableBlocksInfo/TaxableBlocksInfo";

const t = createTranslation(TranslationNS.pages, "company.valuation");

const ValuationOverview: FC = () => {
  const { companyId } = useParams();
  const valuation = useStoreState((state) => state.company.valuation);
  const companies = useStoreState((state) => state.account.user?.companies);
  const fNumber = useFormatNumbers();

  const company = companies?.find((c) => c.id === (companyId ? +companyId : 0));

  //  Add company value guide step 3 =====================================================

  const ref = useRef(false);
  const currentGuideId = useStoreState((state) => state.guideModel.currentGuideId);
  const currentStepId = useStoreState((state) => state.guideModel.currentStepId);
  const moveNext = useStoreActions((actions) => actions.guideModel.moveNext);

  const isGuideActive = currentGuideId === GuideIds.AddCompanyValuation;

  useEffect(() => {
    if (ref.current) return;
    ref.current = true;

    if (isGuideActive && currentStepId === 2) {
      setTimeout(() => {
        moveNext();
      }, 200);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // =====================================================================================

  return (
    <div>
      <div className={classes["overtview-chart"]} id={GuideElementsIds.ValuationChart}>
        <div className="d-flex justify-content-between w-100 mb-5">
          <div>
            <Ui.xl bold color="foregroundHigh" className="mb-1">
              {t("companyValuation")}
            </Ui.xl>
            <Ui.s>{t("totalValue")}</Ui.s>
          </div>
          <div>
            <Ui.xl bold color="foregroundHigh" className="mb-1 text-end">
              {fNumber(valuation?.latestValuation || 0)}
            </Ui.xl>
            <Ui.s>
              {fNumber(
                (valuation?.fdSharePriceIncUnusedSharesInPools || 0) * (valuation?.fdWithUnusedPoolsTotalShares || 0)
              )}{" "}
              fully diluted{" "}
              <LinkHelpText
                type="modal"
                placement="left"
                title={t("fdHelper.companyValuation.title")}
                content={t("fdHelper.companyValuation.description")}
                // className={classes.tooltip}
              >
                <QuestionCircleIcon color={scssVariables.foregroundLow} width={16} height={16} />
              </LinkHelpText>
            </Ui.s>
          </div>
        </div>

        <CompanyValuationChart />
      </div>

      <SharesBlocksInfo />
      {company?.countryId === 166 && <TaxableBlocksInfo />}
    </div>
  );
};

export default ValuationOverview;
