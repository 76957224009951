import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Form, Formik, FormikHelpers } from "formik";
import { defaultTo, isEmpty, isNil } from "ramda";
import * as Yup from "yup";

import { Button, DatePicker, SlidePanel, TextArea, TextField } from "common/components/atoms";
import { dateFormatWithTime } from "common/components/atoms/DatePicker/DatePicker";
import DocumentUploadWithStatuses from "common/components/molecules/DocumentUploadWithStatuses/DocumentUploadWithStatuses";
import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import GuideElementsIds from "../../../../../../../common/guides/guide-configs/guide-elements-ids";
import { GuideIds } from "../../../../../../../common/guides/guide-configs/types";
import { useStoreActions, useStoreState } from "../../../../../../../store/store";
import TransactionsContext from "../../../transactions.context";
import { ChangeNominalValuePostDTO } from "../../../types";
import { documentationDetailsValidationSchema } from "../form-sections/documentation-details/validation";
import { ChangeNominalValueFields } from "./form-fields";

const [t, tValidation] = [
  createTranslation(TranslationNS.pages, "company.transactions"),
  createTranslation(TranslationNS.validation),
];

type Props = {
  onSubmit: (transaction: FormData, resetForm?: boolean) => Promise<boolean>;
  onClose: () => void;
};

const ChangeNominalValueForm: FC<Props> = ({ onSubmit, onClose }) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const { formattedCurrency } = useCurrency();
  const fNumber = useFormatNumbers();

  const { nominalValue, transactedAtMax } = TransactionsContext.useStoreState((state) => state.transactions);
  const nominalValueDetails = TransactionsContext.useStoreState((state) => state.nominalValueDetails);

  const defaultTransactionDate = useMemo(() => {
    const todayMidday = format(new Date().setHours(12, 0, 0, 0), dateFormatWithTime);

    if (nominalValueDetails?.transactionDate) {
      return nominalValueDetails?.transactionDate;
    }

    if (transactedAtMax) {
      if (todayMidday > transactedAtMax) {
        return todayMidday;
      } else {
        return transactedAtMax;
      }
    }

    return todayMidday;
  }, [nominalValueDetails?.transactionDate, transactedAtMax]);

  const formInitialValues: ChangeNominalValuePostDTO = useMemo(() => {
    return {
      [ChangeNominalValueFields.companyId]: Number(companyId),
      [ChangeNominalValueFields.nominalShareValue]: defaultTo(undefined, nominalValueDetails?.nominalShareValue),
      [ChangeNominalValueFields.documentStatusId]: defaultTo(
        undefined as unknown as number,
        nominalValueDetails?.documentStatusId
      ),
      [ChangeNominalValueFields.description]: defaultTo("", nominalValueDetails?.description),
      [ChangeNominalValueFields.transactionDate]: defaultTransactionDate,
      [ChangeNominalValueFields.documents]: [],
      [ChangeNominalValueFields.prevFiles]: defaultTo([], nominalValueDetails?.documentFiles),
    };
  }, [companyId, nominalValueDetails, defaultTransactionDate]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...documentationDetailsValidationSchema(tValidation),
        transactionDate: Yup.string().required(tValidation("required")),
        nominalShareValue: Yup.number()
          .required(tValidation("required"))
          .min(0.000001, tValidation("minNumber", { number: "0,000001" })),
      }),
    []
  );

  const handleSubmit = useCallback(
    async (values: any, actions: FormikHelpers<ChangeNominalValuePostDTO>) => {
      const formData = new FormData();

      if (!isNil(nominalValueDetails)) {
        formData.append("TransactionId", String(nominalValueDetails.transactionId));
      }

      Object.values(ChangeNominalValueFields).forEach((key) => {
        const formValue = values[key];

        if (!formValue) return;

        if (key === ChangeNominalValueFields.documents) {
          if (!isEmpty(formValue)) {
            values.documents.forEach((el: any) => formData.append("FilesData.Files", el));
          }
        } else {
          formData.append(key, String(formValue).trim());
        }
      });

      if (!isEmpty(values["prevFiles"])) {
        values.prevFiles.forEach((el: any) => formData.append("FilesData.OldFileIds", el.fileId));
      }

      const createdSuccessfully = await onSubmit(formData);

      if (!createdSuccessfully) {
        actions.setSubmitting(false);
      }
    },
    [nominalValueDetails, onSubmit]
  );

  //  Add nominal value step 4 ==========================================================

  const ref = useRef(false);
  const currentGuideId = useStoreState((state) => state.guideModel.currentGuideId);
  const currentStepId = useStoreState((state) => state.guideModel.currentStepId);
  const moveNext = useStoreActions((actions) => actions.guideModel.moveNext);

  const isAddNominalValueGuideActive = currentGuideId === GuideIds.AddNominalValue;

  useEffect(() => {
    if (ref.current) return;
    ref.current = true;

    if (isAddNominalValueGuideActive && currentStepId === 2) {
      moveNext();
    }
  }, [currentStepId, isAddNominalValueGuideActive, moveNext]);

  // =====================================================================================

  return (
    <Formik
      onSubmit={handleSubmit}
      enableReinitialize
      initialValues={formInitialValues}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, submitForm, handleChange, setFieldValue, errors, values, touched }) => {
        return (
          <Form>
            <SlidePanel.Header
              title={t("changeNominalValueForm.title")}
              onHide={onClose}
              isEdit={!isNil(nominalValueDetails)}
            />

            <SlidePanel.Section title={t("transactionDetails.title")}>
              <Col xs={6}>
                <DatePicker
                  id={GuideElementsIds.NominalValueDateInput}
                  name={ChangeNominalValueFields.transactionDate}
                  label={t("changeNominalValueForm.date")}
                  date={values.transactionDate}
                  error={errors.transactionDate}
                  isTouched={touched.transactionDate}
                  minDate={transactedAtMax ? new Date(transactedAtMax) : undefined}
                  info={t("transactionDetails.minDateInfo", {
                    minDate: transformDateToCommonDateFormat(transactedAtMax),
                  })}
                  isDateOnlyString
                  isWithTimeSelect
                  onChange={(date) => {
                    setFieldValue(ChangeNominalValueFields.transactionDate, date);
                  }}
                />
              </Col>

              <TextArea
                isOptional
                className="mt-4"
                value={values.description}
                label={t("changeNominalValueForm.description")}
                placeholder={t("changeNominalValueForm.descrPlaceholder")}
                onChange={handleChange("description")}
              />
            </SlidePanel.Section>

            <SlidePanel.Section title={t("changeNominalValueForm.nominalValue")}>
              <Col xs={6}>
                <TextField
                  min={0}
                  type="number"
                  value={values.nominalShareValue}
                  error={errors.nominalShareValue}
                  isTouched={touched.nominalShareValue}
                  onChange={handleChange("nominalShareValue")}
                  label={t("changeNominalValueForm.newNominalValue")}
                  iconRight={formattedCurrency}
                  placeholder={fNumber(nominalValue, "value", { hideCurrencySymbol: true })}
                  info={t("changeNominalValueForm.newNominalValueInfo", {
                    value: fNumber(nominalValue, "value"),
                  })}
                />
              </Col>
            </SlidePanel.Section>

            <SlidePanel.Section title={t("documentationDetails.title")}>
              <DocumentUploadWithStatuses
                oldFilesName={ChangeNominalValueFields.prevFiles}
                newFilesName={ChangeNominalValueFields.documents}
              />
            </SlidePanel.Section>

            <SlidePanel.Actions>
              <Button isLoading={isSubmitting} onClick={submitForm} type="submit" className="me-3">
                {t("sharedFormButtons.submitBtn")}
              </Button>

              <Button isDisabled={isSubmitting} onClick={onClose} variant="secondary">
                {t("sharedFormButtons.cancelBtn")}
              </Button>
            </SlidePanel.Actions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangeNominalValueForm;
