const GuideElementsIds = {
  ImportCaptableBtn: "importCaptableBtn",

  ValuationNavTab: "valuationNavTab",
  EditValuationBtn: "editValuationBtn",
  ValuationForm: "valuationForm",
  ValuationChart: "valuationChart",

  TransactionNavTab: "transactionNavTab",
  ImportTransactionsBtn: "importTransactionsBtn",
  ReplaceExistingTransactionsRadio: "replaceExistingTransactionsRadio",
  AddToExistingTransactionsRadio: "addToExistingTransactionsRadio",

  AddTransactionBtn: "addTransactionBtn",
  ChangeNominalValueBtn: "changeNominalValueBtn",
  NominalValueDateInput: "nominalValueDateInput",
};

export default GuideElementsIds;
