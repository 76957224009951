import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import cn from "classnames";
import { append } from "ramda";

import { getEMPath } from "app/Router/RouterHelper";
import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, LoaderContainer, Tag } from "common/components/atoms";
import SearchField from "common/components/atoms/SearchField/SearchField";
import GuideElementsIds from "common/guides/guide-configs/guide-elements-ids";
import { GuideIds } from "common/guides/guide-configs/types";
import useDebounce from "common/hooks/useDebounce";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import { DownloadIcon, UploadIcon } from "common/icons/svg";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { downloadExcelFile } from "common/utils/download";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import { useStoreActions, useStoreState } from "../../../../store/store";
import captableClasses from "../cap-table/common/actions/CaptableActions.module.scss";
import AddTransactionModal from "./components/add-transaction-modal/add-transaction-modal";
import ConfirmTransactionModal from "./components/confirm-transaction-modal/confirm-transaction-modal";
import DeletePendingTransactionModal from "./components/delete-pending-transaction-modal/delete-pending-transaction-modal";
import BuySellContainer from "./components/forms/buy-sell/buy-sell-container";
import ChangeNominalValueContainer from "./components/forms/change-nominal-value/change-nominal-value-container";
import IssueSharesContainer from "./components/forms/issue-shares/issue-shares-container";
import ManageDocumentsPanel from "./components/forms/manage-documents-panel/manage-documents-panel";
import SplitContainer from "./components/forms/split/split-container";
import RollbackConfirmedTransaction from "./components/rollback-confirmed-transaction/rollback-confirmed-transaction";
import TransactionListWithYearSorting from "./components/transactions-list/transaction-list-with-year-sorting";
import TransactionsContext from "./transactions.context";
import classes from "./transactions.module.scss";
import { Transaction, TransactionCategory, TransactionCategoryIds, TransactionStatus } from "./types";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.transactions"),
  createTranslation(TranslationNS.common, "noAccess"),
];

function matchSearchForFilter(transaction: Transaction, searchValue: string) {
  return (
    transaction.fromName?.toLowerCase().includes(searchValue.toLowerCase()) ||
    transaction.toName?.toLowerCase().includes(searchValue.toLowerCase()) ||
    transaction.fromRepresentativeName?.toLowerCase().includes(searchValue.toLowerCase()) ||
    transaction.toRepresentativeName?.toLowerCase().includes(searchValue.toLowerCase())
  );
}

const Content: FC = () => {
  useDocumentTitleUpdate(t("title"));
  const navigate = useNavigate();
  const { companyId = "0" } = useParams<{ companyId: string }>();

  const scrollRef = useRef<HTMLUListElement>(null);
  const { hasFullAccess } = useFeatures(FEATURES.transactions);

  const isAddTransactionModalOpen = TransactionsContext.useStoreState((state) => state.isAddTransactionModalOpen);
  const isLoading = TransactionsContext.useStoreState((state) => state.isLoading);
  const elementIdToScroll = TransactionsContext.useStoreState((state) => state.elementIdToScroll);
  const { transactions, transactedAtMax } = TransactionsContext.useStoreState((state) => state.transactions);
  const transactionIdToDelete = TransactionsContext.useStoreState((state) => state.transactionIdToDelete);
  const capitalIncreaseIdToDelete = TransactionsContext.useStoreState((state) => state.capitalIncreaseIdToDelete);
  const {
    getTransactionsRequestThunk,
    getIssueSharesTransactionThunk,
    getBuySellTransactionDetailsThunk,
    setLoading,
    setElementIdToScroll,
    setIsAddTransactionModalOpen,
    getSplitTransactionThunk,
    setIsNominalValueFormOpen,
    getNominalValueDetailsThunk,
    deleteCapitalIncreaseThunk,
    deleteTransactionThunk,
    setSelectedTransaction,
    setIsConfirmModalOpen,
    setTransactionIdToDelete,
    setCapitalIncreaseIdToDelete,
    setConfirmedTransactionToRollback,
    clearFormsAction,
  } = TransactionsContext.useStoreActions((actions) => actions);

  // SEARCH
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const handleChangeSearchValue = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }, []);

  const filteredTransactions = useMemo(() => {
    if (!debouncedSearchValue)
      return transactions.reduce((acc, curr) => {
        const transactionYear = new Date(curr.transactedAt).getFullYear();

        if (acc[transactionYear]) {
          acc[transactionYear] = append(curr, acc[transactionYear]);
        } else {
          acc[transactionYear] = [curr];
        }

        return acc;
      }, {} as { [key: string]: Transaction[] });

    return transactions
      .filter((transaction) => {
        const searchValue = debouncedSearchValue.toLowerCase();

        if (matchSearchForFilter(transaction, searchValue)) {
          return true;
        }

        return !!transaction.bundledTransactions?.some((nestedTransaction) =>
          matchSearchForFilter(nestedTransaction, searchValue)
        );
      })
      .reduce((acc, curr) => {
        const transactionYear = new Date(curr.transactedAt).getFullYear();

        if (acc[transactionYear]) {
          acc[transactionYear] = append(curr, acc[transactionYear]);
        } else {
          acc[transactionYear] = [curr];
        }

        return acc;
      }, {} as { [key: string]: Transaction[] });
  }, [debouncedSearchValue, transactions]);

  const handleManageAddTransactionModal = useCallback(() => {
    setIsAddTransactionModalOpen(!isAddTransactionModalOpen);
  }, [isAddTransactionModalOpen, setIsAddTransactionModalOpen]);

  const handleCloseForms = useCallback(() => {
    clearFormsAction();
  }, [clearFormsAction]);

  const handleSubmitForms = useCallback(
    async (transactionId?: string, keepFormOpen: boolean = false) => {
      await getTransactionsRequestThunk(Number(companyId));

      if (!keepFormOpen) {
        handleCloseForms();

        if (transactionId) {
          setElementIdToScroll(transactionId);
        }
      }
    },
    [getTransactionsRequestThunk, companyId, handleCloseForms, setElementIdToScroll]
  );

  const handleOpenDeleteTransaction = useCallback(
    (transactionId: number) => {
      setTransactionIdToDelete(transactionId);
    },
    [setTransactionIdToDelete]
  );

  const handleOpenDeleteCapitalIncrease = useCallback(
    (capitalIncreaseId: number) => {
      setCapitalIncreaseIdToDelete(capitalIncreaseId);
    },
    [setCapitalIncreaseIdToDelete]
  );

  const handleOpenRollbackTransaction = useCallback(
    (transaction: Transaction) => {
      setConfirmedTransactionToRollback(transaction);
    },
    [setConfirmedTransactionToRollback]
  );

  const handleSubmitDeleteTransaction = useCallback(
    async (transactionId: number, isRollback: boolean, isBundle: boolean) => {
      setLoading(true);

      const deletionSuccess = isBundle
        ? await deleteCapitalIncreaseThunk(transactionId)
        : await deleteTransactionThunk(transactionId);

      if (deletionSuccess) {
        notify(
          t(isRollback ? "successTransactionRollback" : "successTransactionDeleted"),
          true,
          "success",
          5000,
          false,
          "top-center"
        );

        await getTransactionsRequestThunk(Number(companyId));

        setTransactionIdToDelete(null);
        return true;
      }
      return false;
    },
    [
      companyId,
      deleteCapitalIncreaseThunk,
      deleteTransactionThunk,
      getTransactionsRequestThunk,
      setLoading,
      setTransactionIdToDelete,
    ]
  );

  const handleOpenConfirmTransaction = useCallback(
    (transaction: Transaction) => {
      setSelectedTransaction(transaction);
      setIsConfirmModalOpen(true);
    },
    [setIsConfirmModalOpen, setSelectedTransaction]
  );

  // Capital increase

  const handleOpenCapitalIncrease = useCallback(() => {
    navigate(
      generatePath(
        getEMPath(["createCapitalIncrease", "general"], {
          transactionId: "new",
        })
      ),
      {
        state: { minTransactionsDate: transactedAtMax },
      }
    );
  }, [navigate, transactedAtMax]);

  const handleSubmitDeleteCapitalIncrease = useCallback(
    async (capitalIncreaseId: number) => {
      const deletionSuccess = await deleteCapitalIncreaseThunk(capitalIncreaseId);

      if (deletionSuccess) {
        notify(t("successTransactionDeleted"), true, "success", 5000, false, "top-center");

        await getTransactionsRequestThunk(Number(companyId));

        setCapitalIncreaseIdToDelete(null);
        return true;
      }
      return false;
    },
    [companyId, deleteCapitalIncreaseThunk, getTransactionsRequestThunk, setCapitalIncreaseIdToDelete]
  );

  const handleOpenEditTransaction = useCallback(
    async (transactionId: number, transactionCategoryId: TransactionCategoryIds) => {
      if (transactionCategoryId === TransactionCategory.Issue) {
        getIssueSharesTransactionThunk(transactionId);
      }

      if (transactionCategoryId === TransactionCategory.Sell) {
        getBuySellTransactionDetailsThunk(transactionId);
      }

      if (transactionCategoryId === TransactionCategory.Split) {
        getSplitTransactionThunk(transactionId);
      }

      if (transactionCategoryId === TransactionCategory.CapitalIncrease) {
        navigate(
          generatePath(getEMPath(["createCapitalIncrease", "general"], { transactionId: String(transactionId) })),
          {
            state: { minTransactionsDate: transactedAtMax },
          }
        );
      }

      if (transactionCategoryId === TransactionCategory.ChangeNominalValue) {
        getNominalValueDetailsThunk(transactionId);
        setIsNominalValueFormOpen(true);
      }
    },
    [
      getBuySellTransactionDetailsThunk,
      getIssueSharesTransactionThunk,
      getNominalValueDetailsThunk,
      getSplitTransactionThunk,
      navigate,
      setIsNominalValueFormOpen,
      transactedAtMax,
    ]
  );

  const hasPendingSplitTransaction = useMemo(() => {
    return !!transactions.find(
      (transaction) =>
        transaction.categoryId === TransactionCategory.Split && transaction.statusId === TransactionStatus.Pending
    );
  }, [transactions]);

  const handleExport = useCallback(async () => {
    downloadExcelFile(`/api/export/transactions/${companyId}`, "Transactions data");
  }, [companyId]);

  const handleImport = useCallback(() => {
    navigate(getEMPath(["importTransactions"]));
  }, [navigate]);

  useEffect(() => {
    getTransactionsRequestThunk(Number(companyId));
  }, [companyId, getTransactionsRequestThunk]);

  useEffect(() => {
    if (elementIdToScroll) {
      const transactionsElements = Array.from(scrollRef?.current?.children || []);
      const elementToScroll = transactionsElements.find((element) => element.id === elementIdToScroll);
      if (elementToScroll) {
        elementToScroll.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      setElementIdToScroll(null);
    }
  }, [elementIdToScroll, setElementIdToScroll]);

  //  Add transactions (recent and clean) step 2 =====================================================

  const ref1 = useRef(false);
  const currentGuideId = useStoreState((state) => state.guideModel.currentGuideId);
  const currentStepId = useStoreState((state) => state.guideModel.currentStepId);
  const moveNext = useStoreActions((actions) => actions.guideModel.moveNext);

  const isImportTransactionsGuideActive =
    currentGuideId === GuideIds.AddRecentTransactions || currentGuideId === GuideIds.ClearAndAddTransactions;

  useEffect(() => {
    if (ref1.current) return;
    ref1.current = true;

    if (isImportTransactionsGuideActive && currentStepId === 0) {
      moveNext();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // =====================================================================================

  // Add nominal value value step 2 ====================================================================

  const ref2 = useRef(false);
  const isAddNominalValueGuideActive = currentGuideId === GuideIds.AddNominalValue;

  useEffect(() => {
    if (ref2.current) return;
    ref2.current = true;

    if (isAddNominalValueGuideActive && currentStepId === 0) {
      moveNext();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // =====================================================================================

  return (
    <PageContent data-testid="transactions-page-test-id">
      <PageContent.Header className="d-block">
        <div className="d-flex align-items-center mb-4">
          <div className="d-flex align-items-center">
            <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
            {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
          </div>

          <Button
            id={GuideElementsIds.ImportTransactionsBtn}
            size="s"
            variant="tertiary"
            className={cn(captableClasses["export-btn"], "ms-auto me-2")}
            iconRight={<DownloadIcon className="ms-half" />}
            onClick={handleImport}
          >
            {t("import")}
          </Button>
          <Button
            size="s"
            variant="tertiary"
            className={cn(captableClasses["export-btn"], "me-2")}
            iconRight={<UploadIcon className="ms-half" />}
            onClick={handleExport}
          >
            {t("export")}
          </Button>

          <SearchField
            value={searchValue}
            onChange={handleChangeSearchValue}
            placeholder={t("searchPlaceholder")}
            className={classes.search}
            wrapperClassName="me-3"
          />

          <Button
            id={GuideElementsIds.AddTransactionBtn}
            isFocusDisabled
            isDisabled={!hasFullAccess}
            tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
            tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
            onClick={handleManageAddTransactionModal}
          >
            {t("addTransaction.btn")}
          </Button>
        </div>
      </PageContent.Header>
      <div>
        <LoaderContainer loading={isLoading}>
          <TransactionListWithYearSorting
            transactions={filteredTransactions}
            handleOpenEditTransaction={handleOpenEditTransaction}
            handleOpenConfirmTransaction={handleOpenConfirmTransaction}
            handleOpenDeleteTransaction={handleOpenDeleteTransaction}
            handleOpenDeleteCapitalIncrease={handleOpenDeleteCapitalIncrease}
            handleOpenRollbackTransaction={handleOpenRollbackTransaction}
            scrollRef={scrollRef}
            isFilteredBySearch={!!searchValue}
          />
        </LoaderContainer>

        <IssueSharesContainer
          onSubmit={handleSubmitForms}
          onClose={handleCloseForms}
          isDisabled={hasPendingSplitTransaction}
        />

        <BuySellContainer
          onSubmit={handleSubmitForms}
          onClose={handleCloseForms}
          isDisabled={hasPendingSplitTransaction}
        />

        <SplitContainer onSubmit={handleSubmitForms} onClose={handleCloseForms} />

        <ChangeNominalValueContainer onSubmit={handleSubmitForms} onClose={handleCloseForms} />

        <ManageDocumentsPanel />

        <ConfirmTransactionModal onClose={handleCloseForms} />

        {!!transactionIdToDelete && (
          <DeletePendingTransactionModal
            transactionId={transactionIdToDelete}
            onSubmit={handleSubmitDeleteTransaction}
            onClose={handleCloseForms}
          />
        )}
        {!!capitalIncreaseIdToDelete && (
          <DeletePendingTransactionModal
            transactionId={capitalIncreaseIdToDelete}
            onSubmit={handleSubmitDeleteCapitalIncrease}
            onClose={handleCloseForms}
          />
        )}

        <RollbackConfirmedTransaction onClose={handleCloseForms} onSubmit={handleSubmitDeleteTransaction} />
      </div>

      <AddTransactionModal
        hasPendingSplitTransaction={hasPendingSplitTransaction}
        handleOpenCapitalIncrease={handleOpenCapitalIncrease}
      />
    </PageContent>
  );
};

const Transactions = () => {
  return (
    <TransactionsContext.Provider>
      <Content />
    </TransactionsContext.Provider>
  );
};

export default Transactions;
